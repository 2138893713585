import { Divider } from '@chakra-ui/layout';
import withHydrationOnDemand from 'react-hydration-on-demand';
import Container from './Container';

interface SpacingProps {
  showDivider: boolean;
  heightBase: string;
  heightSM: string;
  heightMD: string;
  heightLG: string;
  heightXL: string;
  height2XL: string;
}

const SpacingComponent = ({
  showDivider = false,
  heightBase,
  heightSM,
  heightMD,
  heightLG,
  heightXL,
  height2XL,
}: SpacingProps) => {
  const heights = [
    heightBase ? heightBase : 0,
    heightSM,
    heightMD,
    heightLG,
    heightXL,
    height2XL,
  ];
  return (
    <Container height={heights} display="flex" alignItems="center">
      {/* Hide from the accessibility API */}
      {showDivider && <Divider data-testid="divider" aria-hidden />}
    </Container>
  );
};

const Spacing = withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(SpacingComponent);

export default Spacing;
